<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="3">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            新增技师
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form ref="addTechform" :rules="addTechRules" :model="addTech" label-width="120px" style="padding-left:40px">
        <el-row class="left">
          <el-col>
            <el-form-item label="关联用户" >
              <el-tag style="cursor: pointer;" @click="userVisible=true">{{addTech.relevantUser.nickName?addTech.relevantUser.nickName:'选择关联用户'}}</el-tag>
              <el-dialog
                @close="closeUserVisible"
                title="关联用户"
                :visible.sync="userVisible"
                width="50%" top="5vh"
                center>
                <el-form :model="userForm" label-width="80px">
                  <el-form-item label="输入查询">
                    <el-row>
                      <el-col :span="12">
                        <el-input v-model="userForm.userInfo" placeholder="请输入用户昵称/手机号" clearable></el-input>
                      </el-col>
                      <el-col :span="12">
                        <el-button type="primary" style="margin-left:10px" @click="searchUser">搜索</el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-form>
                <el-table
                  highlight-current-row
                  @row-click="rowClick"
                  :data="relevantUserTable"
                  style="width: 100%;margin-top:15px"
                  :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
                  <el-table-column prop="wx_id" label="用户ID"></el-table-column>
                  <el-table-column label="头像">
                    <template slot-scope="scope">
                      <div>
                        <img style="width:60px;height:60px" :src="scope.row.avatarUrl" alt="">
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="nickName" label="昵称"></el-table-column>
                  <el-table-column prop="phoneNumber" label="手机号"></el-table-column>
                </el-table>
                <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
                <div style="text-align:center">
                  <el-button type="default" @click="closeUserVisible">取消</el-button>
                  <el-button type="primary" @click="subUser">确定</el-button>
                </div>
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="技师姓名" prop="jishi_name">
              <el-input v-model="addTech.jishi_name" maxlength="15" show-word-limit placeholder="请输入技师姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="性别" prop="jishi_sex">
              <el-radio v-model="addTech.jishi_sex" label="1">男</el-radio>
              <el-radio v-model="addTech.jishi_sex" label="2">女</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model.number="addTech.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="从业年份" prop="cynf">
              <el-input v-model.number="addTech.cynf" placeholder="请输入从业年份"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="意向工作城市" prop="yxgzcs">
            	<treeselect v-model="addTech.yxgzcs" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer" :show-count="true" placeholder="请选择工作城市" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="所在地址" prop="address">
              <el-input v-model="addTech.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="经度" prop="lng">
              <el-input v-model="addTech.lng" placeholder="请输入经度"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="纬度" prop="lat">
              <el-input v-model="addTech.lat" placeholder="请输入纬度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin:5px 0 0 5px">
            <el-button type="primary" plain size="mini" @click="getJingweidu">获取经纬度</el-button>
          </el-col>
          <Map :jingweiVisible.sync="jingweiVisible" @close="closeMapDialog" @subJINGWEI="subJINGWEI"></Map>
        </el-row>
        <el-row class="left">
          <el-col :span="14">
            <el-form-item label="技师介绍" prop="jianjie">
              <el-input type="textarea" :rows="10" maxlength="300" show-word-limit v-model="addTech.jianjie" placeholder="请输入技师介绍"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="身份证号" prop="sfzh">
              <el-input v-model="addTech.sfzh" placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="身份证照片" prop="sfzh_img">
              <div class="previewUpload">
                <div v-show="addTech.sfzh_img.length>0" class="previewItem" v-for="(item,index) in addTech.sfzh_img" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delIdCardItem(index)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog((3-addTech.sfzh_img.length),'idCardPhoto')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="资格证书" prop="zgzs_img">
              <div class="previewUpload">
                <div v-show="addTech.zgzs_img.length>0" class="previewItem" v-for="(item,index) in addTech.zgzs_img" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delCertificateItem(index)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog((15-addTech.zgzs_img.length),'certificate')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="工作形象照" prop="gzxxz_img">
              <div class="previewUpload">
                <div v-show="addTech.gzxxz_img.length>0" class="previewItem" v-for="(item,index) in addTech.gzxxz_img" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delWorkPhotoItem(index)"></i>
                </div>
                <div class="previewItems" v-if="addTech.gzxxz_img.length==0" @click="openUploadDialog((1-addTech.gzxxz_img.length),'workPhoto')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="个人生活照" prop="grshz_img">
              <div class="previewUpload">
                <div v-show="addTech.grshz_img.length>0" class="previewItem" v-for="(item,index) in addTech.grshz_img" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delLifePhotoItem(index)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog((9-addTech.grshz_img.length),'lifePhoto')"><i class="el-icon-plus previewadd"></i></div>
              </div>
              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
            </el-form-item>
          </el-col>
        </el-row> 
      </el-form>
      <el-button type="primary" @click="subAdd">提交</el-button>
      <el-button type="default" @click="back">返回</el-button>
    </el-card>
  </div>
</template>

<script>
let that;
let timer = null;
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from '@/components/Pagination/Pagination.vue'
import Map from '@/components/Map/Map.vue'
import Upload from '@/components/Upload/Upload.vue'
export default {
  components:{
    Treeselect,Pagination,Upload,Map
  },
  data () {
    // 验证手机
    var checkphone = (rule, value, cb) => {
      const regPhone =
        /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regPhone.test(value)) {
        return cb();
      } else {
        cb(new Error("请输入合法手机号"));
      }
    };
    //验证身份证
    var checkIdcard=(rule, value, cb)=>{
      const regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (regId.test(value)) {
          return cb();
        } else {
          cb(new Error("请输入正确身份证号"));
        }
    }
    return {
      addTechRules:{
        jishi_name:[
          {required: true, message:'请输入技师姓名', trigger: 'blur'}
        ],
        jishi_sex:[
          {required: true, message:'请输入选择性别', trigger: 'blur'}
        ],
        phone:[
          {required: true, message:'手机号不得为空', trigger: 'blur'},
          { validator: checkphone, trigger: "blur" },
        ],
        cynf:[
          {required: true, message:'请输入从业年份', trigger: 'blur'},
          { type: "number", message: "请输入数字" },
        ],
        yxgzcs:[
          {required: true, message:'请选择意向工作城市', trigger: 'blur'},
        ],
        address:[
          {required: true, message:'请输入所在地址', trigger: 'blur'},
        ],
        lng:[
          {required: true, message:'请输入经度', trigger: 'blur'},
        ],
        lat:[
          {required: true, message:'请输入纬度', trigger: 'blur'},
        ],
        jianjie:[
          {required: true, message:'请输入技师介绍', trigger: 'blur'},
        ],
        sfzh:[
          {required: true, message:'请输入身份证号', trigger: 'blur'},
          { validator: checkIdcard, trigger: "blur" },
        ],
        sfzh_img:[
          { required: true, message: "请上传图片", trigger: "change" }
        ],
        zgzs_img:[
          { required: true, message: "请上传图片", trigger: "change" }
        ],
        gzxxz_img:[
          { required: true, message: "请上传图片", trigger: "change" }
        ],
        grshz_img:[
          { required: true, message: "请上传图片", trigger: "change" }
        ]
      },
      addTech:{
      	wx_id: '',
        relevantUser:{},
        jishi_name:'',
        jishi_sex:'1',
        phone:'',
        cynf:'',
        yxgzcs:null,
        address:'',
        lng:'',
        lat:'',
        jianjie:'',
        sfzh:'',
        sfzh_img:[],
        zgzs_img:[],
        gzxxz_img:[],
        grshz_img:[]
      },
      uploadVisible:false,
      userVisible:false,
      jingweiVisible:false,
      userForm:{
        userInfo:''
      },
      pictureType:'',
      chooseImgCount:0,
      relevantUserTable:[],
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total:0,
      relevantUserfalse:{},
      city_list: [],
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getCityList();
			this.getCustomList();
			if(this.$route.query.id){
				this.getJishiDetail();
			}
		}
  },
  methods: {
  	getCityList(){
  		var url = 'city/city_list';
			let params = {
				is_del: 1
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.city_list = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getJishiDetail(){
  		var url = 'jishi/jishi_detail';
			let params = {
				jishi_id: this.$route.query.id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.addTech = res.detail;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getCustomList(){
  		var url = 'custom/user_list';
			let params = {
				nickName: this.userForm.userInfo,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.relevantUserTable = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	//转换菜单数据结构
		normalizer(node) {
			if(node.children && !node.children.length) {
				delete node.children;
			}
			return {
				id: node.city_id,
				label: node.city_name,
				children: node.children,
			};
		},
    back(){
      this.$router.go(-1)
    },
    closeDelDialog(){
      this.uploadVisible=false
    },
    openUploadDialog(count,pictureType){
      this.pictureType = pictureType;
      this.chooseImgCount = count;
      this.uploadVisible = true;
    },
    receiveRes(chooseList, pictureType){
      if(pictureType==='idCardPhoto'){
        this.addTech.sfzh_img=this.addTech.sfzh_img.concat(chooseList)
      }else if(pictureType==='certificate'){
        this.addTech.zgzs_img=this.addTech.zgzs_img.concat(chooseList)
      }else if(pictureType==='workPhoto'){
        this.addTech.gzxxz_img=this.addTech.gzxxz_img.concat(chooseList)
      }else if(pictureType==='lifePhoto'){
        this.addTech.grshz_img=this.addTech.grshz_img.concat(chooseList)
      }
      this.uploadVisible=false
    },
    delIdCardItem(index){
      this.addTech.sfzh_img.splice(index,1)
    },
    delCertificateItem(index){
      this.addTech.zgzs_img.splice(index,1)
    },
    delWorkPhotoItem(index){
      this.addTech.gzxxz_img.splice(index,1)
    },
    delLifePhotoItem(index){
      this.addTech.grshz_img.splice(index,1)
    },
    //打开经纬度弹窗
    getJingweidu(){
      this.jingweiVisible=true
    },
    //关闭经纬度弹窗
    closeMapDialog(){
      this.jingweiVisible=false
    },
    //获取经纬度
    subJINGWEI(lat,lng){
      this.addTech.lat=lat
      this.addTech.lng=lng
      this.jingweiVisible=false
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getCustomList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getCustomList();
    },
 
    rowClick(row){
      this.relevantUserfalse = row;
    },
    searchUser(){
    	this.getCustomList();
    },
    closeUserVisible(){
      this.userVisible = false;
      this.userForm.userInfo = '';
      this.relevantUserfalse = {};
    },
    //关联用户
    subUser(){
      this.addTech.relevantUser = this.relevantUserfalse;
      this.userVisible = false;
    },
    //提交
    subAdd(){
      this.$refs.addTechform.validate(valid=>{
        if(!valid) return;
        else{
        	if(timer != null){clearTimeout(timer);}
					timer = setTimeout(() => {
	        	if(that.addTech.relevantUser.wx_id){
	        		that.addTech.wx_id = that.addTech.relevantUser.wx_id;
	        	}
	        	that.addTech.sfzh_json = JSON.stringify(that.addTech.sfzh_img);
		      	that.addTech.zgzs_json = JSON.stringify(that.addTech.zgzs_img);
		      	that.addTech.gzxxz_json = JSON.stringify(that.addTech.gzxxz_img);
		      	that.addTech.grshz_json = JSON.stringify(that.addTech.grshz_img);
	        	var url = 'jishi/addOrUpdate';
		        let params = that.addTech;
		        that.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			that.$refs.addTechform.resetFields();
	        			that.$message.success("操作成功");
	        			that.$router.push({path: '/technician_manage'});
	        		}else{
	        			that.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            that.$message.error('网络错误');
		        });
		      },1000);
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import './TechAdd.scss';
</style>